import { useLocation } from "@reach/router";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { loadScript } from "@paypal/paypal-js";

import { Button } from "../../components";
import SuccessIcon from "../../images/successIcon.png";
import {
  BACKEND_API_URL,
  PAYPAL_CLIENT_ID,
  PAYPAL_MERCHANT_ID,
} from "../../config";

const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

const start = async (
  merchantIds,
  urlAccessToken,
  urlSessionId,
  callbackPayment
) => {
  try {
    loadScript({
      "client-id": PAYPAL_CLIENT_ID,
      "merchant-id": `${merchantIds.join(",")}`, // [merchantId, PAYPAL_MERCHANT_ID],
      currency: "GBP",
    }).then((paypal) => {
      console.log(merchantIds);
      paypal
        .Buttons({
          // Order is created on the server and the order id is returned
          createOrder: async (data, actions) => {
            try {
              const headers = {
                Authorization: `Bearer ${urlAccessToken}`,
              };

              const result = await axios.get(
                `${BACKEND_API_URL}/api/v1/payment/session/order/create/${Number(
                  urlSessionId
                )}`,
                {
                  headers,
                }
              );

              console.log(result);

              const orderId = result.data.orderId;

              return orderId;
            } catch (error) {
              console.log("er", error);
            }
          },
          // Finalize the transaction on the server after payer approval
          onApprove: async (data, actions) => {
            try {
              const { orderID } = data;

              const headers = {
                Authorization: `Bearer ${urlAccessToken}`,
              };

              const result = await axios.get(
                `${BACKEND_API_URL}/api/v1/payment/session/order/capture/${orderID}`,
                {
                  headers,
                }
              );

              callbackPayment();

              const paypalBtnCont = document.getElementById(
                "paypal-button-container"
              );
              if (paypalBtnCont !== null) {
                paypalBtnCont.innerHTML = "";
              }
              const clickableLink = document.createElement("a");
              clickableLink.href = "joosup://Main/Dashboard/showPayment";
              clickableLink.click();
            } catch (error) {
              return actions.restart();
            }
          },
        })
        .render("#paypal-button-container");
    });
  } catch (error) {
    console.log("error", error);
  }
};

const Payment2 = () => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [paymentFinished, setPaymentFinished] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(null);
  const token = useQuery("token");
  const sessionId = useQuery("sessionId");

  useEffect(() => {
    const fetchSessionDetails = async () => {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const res = await axios.get(
        `${BACKEND_API_URL}/api/v1/session/details/${sessionId}`,
        { headers }
      );
      console.log(res.data);
      setSessionDetails(res.data);
    };
    fetchSessionDetails();
  }, []);

  const callbackPayment = () => {
    setPaymentFinished(true);
  };

  // useEffect(() => {
  //   if (sessionDetails?.merchantId) {
  //     start(sessionDetails.merchantId, token, sessionId);
  //   }
  // }, [sessionDetails]);

  if (sessionDetails === null)
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Loading</h1>
      </div>
    );

  const { session, summary } = sessionDetails;

  let cost = 0;
  if (summary) {
    cost = parseFloat(summary.sessionCost);
  }
  if (summary && summary.serviceFee) {
    cost = cost + parseFloat(summary.serviceFee);
  }

  const goBackToApp = () => {
    const paypalBtnCont = document.getElementById("paypal-button-container");
    if (paypalBtnCont !== null) {
      paypalBtnCont.innerHTML = "";
    }

    const clickableLink = document.createElement("a");
    clickableLink.href = "joosup://Main/Dashboard/showPayment";
    clickableLink.click();
  };

  return (
    <>
      <Helmet>
        {/* <script src="checkout-script.js" type="module" defer /> */}
        <script
          src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"
          defer
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment.min.js"
          defer
        />
        {/*<script
          src="https://unpkg.com/@paypal/paypal-js@5.1.4/dist/iife/paypal-js.min.js"
          defer
        />*/}
      </Helmet>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#F1F3F5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 16px",
          }}
        >
          {/* <img
            src={logo}
            alt="Joosup logo"
            style={{ maxWidth: "100%", height: "auto" }}
            maxWidth="360px"
          /> */}
          {!paymentFinished ? (
            <>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: 22,
                  marginTop: 70,
                  marginBottom: 24,
                  textAlign: "center",
                  color: "#3B3558",
                }}
              >
                Payment for your session
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "24px 0",
                  width: "100%",
                  borderRadius: 8,
                }}
              >
                <p style={{ fontWeight: 400, fontSize: 18 }}>Total cost:</p>
                <p style={{ fontWeight: 700, fontSize: 32, lineHeight: 1 }}>
                  £{cost.toFixed(2)}
                </p>
              </div>
              {!buttonClicked && (
                <Button
                  onClick={
                    buttonClicked
                      ? () => {}
                      : () => {
                          let merchantIds = [sessionDetails.merchantId];
                          if (sessionDetails.summary.serviceFee > 0) {
                            merchantIds.push(PAYPAL_MERCHANT_ID);
                          }
                          console.log("merchantIds", merchantIds);
                          setButtonClicked(true);
                          start(
                            merchantIds, //merchantIds.length > 1 ? "*" : merchantIds[0],
                            token,
                            sessionId,
                            callbackPayment
                          );
                        }
                  }
                  text="Proceed with payment"
                />
              )}
              {/* <p style={{ color: "white", fontWeight: 700, fontSize: 18 }}>
              Proceed with Payment
            </p>
          </Button> */}
              <div
                style={{
                  height: 0.5,
                  width: "100%",
                  backgroundColor: "#B0B9C6",
                  margin: "16px 0",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <div id="paypal-button-container" />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SuccessIcon}
                  alt="Success Icon"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    alignSelf: "center",
                    marginTop: 70,
                  }}
                />
                <h2 style={{ fontWeight: 700, fontSize: 26, marginTop: 24 }}>
                  Success
                </h2>
                <p style={{ fontWeight: 400, fontSize: 16, marginTop: 16 }}>
                  Payment for your session was successful!
                </p>
              </div>

              <Button text="Go back to the app" onClick={goBackToApp} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment2;

import { useLocation } from "@reach/router";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

import { Button } from "../../components";
import logo from "../../images/joosup.png";
import SuccessIcon from "../../images/successIcon.png";
import { BACKEND_API_URL, PAYPAL_CLIENT_ID } from "../../config";

const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

const start = async (accessToken, subscriptionId, callbackPayment) => {
  try {
    let headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    let response = await axios.get(
      `${BACKEND_API_URL}/api/v1/payment/subscription/prepare`,
      { headers }
    );
    const paypalSubscriptionId = await response.data.subscriptionTrackingId;

    // eslint-disable-next-line no-undef
    paypal
      .Buttons({
        createSubscription: function (data, actions) {
          return actions.subscription.create({
            plan_id: subscriptionId,
            custom_id: paypalSubscriptionId,
          });
        },
        onApprove: function (data, actions) {
          console.log("approved");
          callbackPayment();
          const paypalBtnCont = document.getElementById(
            "paypal-button-container"
          );
          if (paypalBtnCont !== null) {
            paypalBtnCont.innerHTML = "";
          }
          const clickableLink = document.createElement("a");
          clickableLink.href =
            "joosup://Main/Settings/SubscriptionsStack/Subscriptions?type=subscriptionProcessed";
          clickableLink.click();
        },
      })
      .render("#paypal-button-container");
  } catch (e) {}
};

const Payment = () => {
  const [paymentFinished, setPaymentFinished] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const token = useQuery("token");
  const subscriptionId = useQuery("subId");

  const callbackPayment = () => {
    setPaymentFinished(true);
  };

  const callbackClick = () => {
    const clickableLink = document.createElement("a");
    clickableLink.href =
      "joosup://Main/Settings/SubscriptionsStack/Subscriptions?type=subscriptionProcessed";
    clickableLink.click();
  };
  let cost = "1.49";
  if (subscriptionId === "P-8ST13913P6167200RMPABYYQ") cost = "14.99";
  if (subscriptionId === "P-94F17679HW505640AMPABZOI") cost = "1.49";
  return (
    <>
      <Helmet>
        {/* <script src="checkout-script.js" type="module" defer /> */}
        <script
          src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"
          defer
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment.min.js"
          defer
        />
        <script
          src="https://unpkg.com/@paypal/paypal-js@5.1.1/dist/iife/paypal-js.min.js"
          defer
        />
        <script
          src={`https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true&intent=subscription`}
        />
      </Helmet>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#F1F3F5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 16px",
          }}
        >
          <img
            src={logo}
            alt="Joosup logo"
            style={{ maxWidth: "100%", height: "auto" }}
            maxWidth="360px"
          />
          {!paymentFinished ? (
            <>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: 22,
                  marginTop: 70,
                  marginBottom: 24,
                  textAlign: "center",
                  color: "#3B3558",
                }}
              >
                Payment for your subscription
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "24px 0",
                  width: "100%",
                  borderRadius: 8,
                }}
              >
                <p style={{ fontWeight: 400, fontSize: 18 }}>Total cost:</p>
                <p style={{ fontWeight: 700, fontSize: 32, lineHeight: 1 }}>
                  £{cost}
                </p>
              </div>
              <Button
                onClick={
                  buttonClicked
                    ? () => {}
                    : () => {
                        setButtonClicked(true);
                        start(token, subscriptionId, callbackPayment);
                      }
                }
                text="Proceed with payment"
              />
              <div
                style={{
                  height: 0.5,
                  width: "100%",
                  backgroundColor: "#B0B9C6",
                  margin: "16px 0",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <div id="paypal-button-container" />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SuccessIcon}
                  alt="Success Icon"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    alignSelf: "center",
                    marginTop: 70,
                  }}
                />
                <h2 style={{ fontWeight: 700, fontSize: 26, marginTop: 24 }}>
                  Success
                </h2>
                <p style={{ fontWeight: 400, fontSize: 16, marginTop: 16 }}>
                  Your payment was succesful!
                </p>
              </div>
              <Button text="Go back to the app" onClick={callbackClick} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;

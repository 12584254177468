import { useLocation } from "@reach/router";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Button } from "../../components";
import logo from "../../images/joosup.png";
import { useState } from "react";
import { BACKEND_API_URL } from "../../config";

const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

const Receipts = () => {
  const [pdf, setPdf] = useState({ data: "", title: "" });
  const token = useQuery("token");
  const sessionId = useQuery("sessionId");
  const isExtension = useQuery("isExtension");

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf("safari") !== -1) {
      if (ua.indexOf("chrome") > -1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const downloadFile = async () => {
    const link =
      isExtension === "true"
        ? `${BACKEND_API_URL}/api/v1/payment/receipt/extension/${Number(
            sessionId
          )}`
        : `${BACKEND_API_URL}/api/v1/payment/receipt/session/${Number(
            sessionId
          )}`;
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const res = await axios.get(link, { headers });
      const pdf = res.data.pdf;
      const linkSource = `data:application/pdf;base64,${pdf}`;

      const fileName = res.data.title;

      setPdf({ data: linkSource, title: fileName });

      // alert("Your receipt has been downloaded.");
    } catch (e) {
      alert(`Error downloading your receipt`);
    }
  };

  useEffect(() => {
    if (isExtension) {
      downloadFile();
    }
  }, [isExtension]);
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#F1F3F5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
        }}
      >
        <img
          src={logo}
          alt="Joosup logo"
          style={{ maxWidth: "100%", height: "auto" }}
          maxWidth="360px"
        />
        <h1
          style={{
            fontWeight: 700,
            fontSize: 22,
            marginTop: 70,
            color: "#3B3558",
            textAlign: "center",
            marginBottom: 16,
          }}
        >
          Your receipt has been generated
        </h1>

        {!pdf.data && !pdf.title ? (
          <>
            <Button text="Generate receipt" onClick={downloadFile} />
          </>
        ) : (
          <div
            style={{
              marginTop: 24,
            }}
          >
            <p style={{ textAlign: "center" }}>
              Your pdf was generated, click below to download it:
            </p>
            <a
              href={pdf.data}
              download={isSafari() ? undefined : pdf.title}
              title={pdf.title}
              target="_blank"
              id="downloadReceipt"
              alt="Download Receipt"
              rel="noreferrer"
              style={{
                color: "white",
                fontWeight: 700,
                fontSize: 18,
                textDecoration: "none",
                backgroundColor: "#32A6D5",
                borderRadius: 8,
                padding: "16px 0",
                width: "100%",
                border: "none",
                display: "flex",
                justifyContent: "center",
                marginTop: 24,
                cursor: "pointer",
              }}
            >
              Download Receipt
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Receipts;

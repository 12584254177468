import { useLocation } from "@reach/router";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Button } from "../../components";
import logo from "../../images/joosup.png";
import { BACKEND_API_URL } from "../../config";

const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

const Invoices = () => {
  const token = useQuery("token");
  const invoiceId = useQuery("invoiceId");

  const downloadFile = async () => {
    const headers = { Authorization: `Bearer ${token}` };
    const res = await axios.get(
      `${BACKEND_API_URL}/api/v1/payment/invoice/${invoiceId}`,
      { headers }
    );
    const pdf = res.data.pdf.toString("base64");
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = res.data.title;
    downloadLink.setAttribute("download", fileName);
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
    alert("Your receipt has been downloaded.");
  };

  useEffect(() => {
    downloadFile();
  }, []);
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#F1F3F5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
        }}
      >
        <img
          src={logo}
          alt="Joosup logo"
          style={{ maxWidth: "100%", height: "auto" }}
          maxWidth="360px"
        />
        <h1
          style={{
            fontWeight: 700,
            fontSize: 22,
            marginTop: 70,
            color: "#3B3558",
            textAlign: "center",
            marginBottom: 16,
          }}
        >
          Your invoice has been generated
        </h1>
        <p style={{ fontWeight: 400, fontSize: 16, textAlign: "center" }}>
          Invoice didn't download automatically? Click the button to download
          manually.
        </p>
        <Button text="Generate invoice" onClick={downloadFile} />
      </div>
    </div>
  );
};

export default Invoices;

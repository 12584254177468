import React, { FC } from "react";

const Button = ({ onClick, text }) => {
  return (
    <button
      id="submit"
      type="button"
      onClick={onClick}
      style={{
        backgroundColor: "#32A6D5",
        borderRadius: 8,
        padding: "16px 0",
        width: "100%",
        border: "none",
        marginTop: 24,
      }}
    >
      <p style={{ color: "white", fontWeight: 700, fontSize: 18 }}>{text}</p>
    </button>
  );
};

export default Button;
